import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import styled, { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import GraphQLErrorList from '../components/graphqlerrorlist';
import SEO from '../components/seo';
import { toPlainText } from '../lib/helpers';
import BlogPost from '../components/blog-post';

import Navbar from 'common/src/containers/Saas/Navbar';
import Footer from 'common/src/containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';

import { saasTheme } from 'common/src/theme/saas';

export const pageQuery = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
        caption
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`;

const BlogPostTemplate = props => {
  const { data, errors } = props;
  const post = data && data.post;

  return (
    <ThemeProvider theme={saasTheme}>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
        </Sticky>
        <Wrapper>
          <Container>
            <Box>
              {errors && <GraphQLErrorList errors={errors} />}
              {post && <BlogPost {...post} />}
            </Box>
          </Container>
        </Wrapper>
        <Footer />
      </ContentWrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled.section`
  padding: 104px 0 0;
  background: #fafbff;
`;

BlogPostTemplate.propTypes = {
  data: PropTypes.object,
};

export default BlogPostTemplate;
